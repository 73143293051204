import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import { Nav } from "./components";

const theme = createTheme({
  palette: {
    background: { default: "#dcddd8" },
    primary: { main: "#4e4247", contrastText: "#dfd3c3" },
    secondary: { main: "#585858", contrastText: "#dedfdc" },
  },
  typography: { fontFamily: "'Crimson Text'" },
});

export default function App() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("home");
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Nav />
      <Outlet />
    </ThemeProvider>
  );
}
