import { Floorplan } from "../svg";
import { SVGContainer } from "../components";

export default function Home() {
  return (
    <SVGContainer>
      <Floorplan />
    </SVGContainer>
  );
}
